import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { useCart } from '@chordcommerce/gatsby-theme-autonomy'
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

// Components
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import { getSrc, getSrcSet } from 'gatsby-plugin-image'
import ChordServices, { KIT_PROPERTY_NAME } from '~/api/chord-services'

const allSKUs = []
const AmbassadorShopKitsPage = ({ data }) => {
  const { addToCart, cart } = useCart()
  const [cartHasKit, setCartHasKit] = useState(false)
  const [productsKits, setProductsKits] = useState([])

  const { nodes: kits } = { ...data.allContentfulKit }

  // Getting all kits SKUs
  kits.forEach(kit => allSKUs.push(kit.sku))

  useEffect(() => {
    // Check if the cart has any kits
    if (cart && cart.data && cart.data.lineItems) {
      cart.data.lineItems.forEach(item => {
        if (allSKUs.indexOf(item.variant.sku) !== -1) {
          setCartHasKit(true)
        }
      })
    }
  }, [cart])

  useEffect(() => {
    ChordServices.allProducts()
      .then(result => {
        const products = []
        result.data.products.forEach(chordProduct => {
          // Only Kits products
          if (chordProduct.product_properties.some(item => item.property_name === KIT_PROPERTY_NAME)) {
            // Check if the chord product has a sibling in Contentful
            kits.forEach(contenfulProduct => {
              if (contenfulProduct.sku === chordProduct.master.sku) {
                products.push({
                  ...contenfulProduct,
                  ...{
                    isOnStock: chordProduct.total_on_hand > 0,
                    display_price: chordProduct.display_price
                  }
                })
              }
            })
          }
        })
        setProductsKits(products)
      })
      .catch(() => {
        console.error('error fetching products')
      })
  }, [])

  const handleAddToCart = async sku => {
    try {
      await addToCart(sku, 1)
    } catch (error) {
      // TODO: Add Toast Alert
    }
  }
  return (
    <Layout>
      <Metadata title="Ambassador Shop Kit" />
      <div className="container  py-5">
        <div>
          <h2 className="fs-72 font-opensans-light txt-gray-900">Join Our Team</h2>
          <div className="fontSFPro-bold fs-22 txt-gray-900 mt-5">Select your starter kit</div>
          <div className="fontSFPro-regular fs-18 txt-gray-900 mt-3">
            Purchase one of our special ambassador kits from the options below:
          </div>
          <div className="row d-flex flex-wrap gx-5 mt-3">
            {productsKits.length > 0 &&
              productsKits.map(productKit => {
                return (
                  <div className="col-md-4 d-flex flex-wrap flex-column my-3" key={productKit.id}>
                    <div>
                      {productKit.images.length > 0 && (
                        <img
                          src={getSrc(productKit.images[0])}
                          srcSet={getSrcSet(productKit.images[0])}
                          alt={`${productKit.name} Products`}
                          className="img-fluid"
                        />
                      )}
                    </div>
                    <div className="fs-20 fontSFPro-bold txt-gray-900 mt-3">{productKit.name}</div>
                    <div className="description_line overflow-hidden d-webproductKit-box fs-16 font-opensans-regular lh-20 txt-gray-900 mt-2">
                      {productKit.shortDescription}
                    </div>
                    <div className="fs-20 fontSFPro-regular txt-gray-900 mt-2">PRICE: {productKit.display_price}</div>
                    <div className="d-flex flex-column flex-md-row justify-content-end me-5 mt-4">
                      <OverlayTrigger
                        overlay={cartHasKit ? <Tooltip>Your already have a kit in your cart!</Tooltip> : <></>}
                      >
                        <span className="d-inline-block">
                          <Button
                            onClick={() => handleAddToCart(productKit.sku)}
                            variant="primary"
                            disabled={cartHasKit || cart.isFetching}
                            className="fs-18 fontSFPro-bold txt-gray-100 rounded-0 kit_btn"
                          >
                            {cart.isFetching ? 'Loading…' : 'Add To Bag'}
                          </Button>
                        </span>
                      </OverlayTrigger>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AmbassadorShopKitsPage

export const query = graphql`
  query ProductKitQuery {
    allContentfulKit(sort: { fields: priority, order: DESC }) {
      nodes {
        id
        name
        sku
        products {
          id
          sku
          slug
          name
        }
        images {
          gatsbyImageData
        }
        shortDescription
      }
    }
  }
`
// export const query = graphql`
//   query ProductKitQuery {
//     allContentfulProductKit(sort: { order: DESC, fields: priority }) {
//       nodes {
//         id
//         name
//         sku
//         images {
//           gatsbyImageData
//         }
//         shortDescription
//       }
//     }
//   }
// `
